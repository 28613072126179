import { createAsyncThunk } from "@reduxjs/toolkit";
import { postLoginService } from "Services";

export const createQuestionnaires = createAsyncThunk(
  "questionnaires/createQuestionnaires",
  async (params) => {
    try {
      let { isEdit, questionnaireId } = params;
      const url = `/organization/survey${
        isEdit ? `/edit/${questionnaireId}` : ""
      }`;
      const response = await postLoginService.post(url, params);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);

export const getAllQuestionnaire = createAsyncThunk(
  "questionnaires/getAllQuestionnaire",
  async (params) => {
    try {
      let url = `/organization/survey/all`;
      if (params) {
        url = `${url}?skip=${params.skip}&limit=${params.limit}`;
      }
      const response = await postLoginService.get(url);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);

export const getIndividualQuestionnaire = createAsyncThunk(
  "questionnaires/getIndividualQuestionnaire",
  async (params) => {
    try {
      const url = `/organization/survey/individual/${params.id}`;
      const response = await postLoginService.get(url);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);

export const validateSurveyName = createAsyncThunk(
  "questionnaires/validateSurveyName",
  async (params) => {
    try {
      let url = `/organization/survey/checkSurveyName?surveyName=${params.surveyName}`;
      if (params?._id) {
        url = `${url}&surveyId=${params._id}`;
      }

      const response = await postLoginService.get(url);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);

export const uploadSurveyImage = createAsyncThunk(
  "questionnaires/uploadSurveyImage",
  async (params) => {
    try {
      const url = `/organization/survey/uploadImage/${params.surveyName}`;
      const response = await postLoginService.post(url, params.formData);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);

export const removeSurveyImage = createAsyncThunk(
  "questionnaires/removeSurveyImage",
  async (params) => {
    try {
      const url = `/organization/survey/removeImage`;
      const response = await postLoginService.post(url, params);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);

export const setSurveyLogic = createAsyncThunk(
  "questionnaires/setSurveyLogic",
  async (params) => {
    try {
      let { surveyId, questions } = params;
      const url = `/organization/survey/setLogic/${surveyId}`;
      const response = await postLoginService.post(url, { questions });
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);
