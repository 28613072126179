import React, { Component } from "react";
import { connect } from "react-redux";
import logo from "./../../_assets/images/logo-login.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Dropdown from "react-bootstrap/Dropdown";
import { withTranslation } from "react-i18next";
import "./../../_assets/scss/header.scss";
import { Link } from "react-router-dom";
import { commonFunctions } from "_utilities";

class Header extends Component {
  constructor(props) {
    super(props);
  }

  onClickLogout = (e) => {
    e.preventDefault();
    localStorage.clear();
    window.location.href = `${window.location.origin}/prelogin/login`;
  };

  render() {
    const { t } = this.props;
    let initial = "";
    let name = "";
    let user = commonFunctions.getUserData();
    if (user) {
      name = user.userDetails?.name;
      if (name?.split(" ")?.length > 1) {
        name = name.split(" ")[0];
        initial = name[0]?.charAt(0)?.toUpperCase();
      }
    }
    return (
      <div className="header">
        <div className="container-fluid">
          <div className="row d-flex align-items-center justify-content-start">
            <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-6"
              style={{ zIndex: 1 }}
            >
              <div className="logo d-inline-block">
                <Link to="/postlogin/dashboard">
                  <img src={logo} alt="" />
                </Link>
              </div>
            </div>
            <div className="col-xl-10 col-lg-10 col-md-8 col-sm-6 col-6">
              <div className="float-right profile">
                <div className="rounded-circle d-inline-block align-middle text-center profile-image">
                  <span>{name?.charAt(0)?.toUpperCase()}</span>
                </div>
                <div className="btn-group d-inline-block align-middle profile-dropdown">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      {name}
                      <span className="d-inline-block align-middle pt-1">
                        <KeyboardArrowDownIcon />
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          this.props.history.push("/postlogin/profile");
                        }}
                      >
                        Profile
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          this.props.history.push("/postlogin/change-password");
                        }}
                      >
                        Change Password
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={this.onClickLogout}
                        className="logout"
                      >
                        {t("Log out")}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loggedInUser } = state.auth;
  return {
    loggedInUser,
  };
}

const connectedHeader = withTranslation()(connect(mapStateToProps)(Header));
export { connectedHeader as Header };
