import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import CircularProgress from '@mui/material/CircularProgress'; 

class CommonLoader extends Component {
  render() {
   
    return ReactDOM.createPortal(
      <div style={overlayStyle}>
        <CircularProgress style={{ color: '#434bdf' }} />
      </div>,
      document.body 
    );
  }
}


const overlayStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 9999,
};

export default CommonLoader;
