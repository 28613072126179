import { createSlice } from "@reduxjs/toolkit";
import {
  createResearchProject,
  getAllResearchProjects,
  getIndividualResearchProject,
  editResearchProject,
  getAllMeta,
  getResearchElligibleRespondents,
  getResearchFinalizeRespondents,
  getMetaForRespondent,
  getScreenedQuestionForRespondent,
  finalizeRespondent,
  moveFinalizedRespondentToHisory,
  getFinalizedRespondentContact,
} from "./researchProjectThunk";
import { status } from "_constants";

const ResearchMetaSlice = createSlice({
  name: "researchProject",
  initialState: {
    getAllMetaRes: {
      status: null,
      data: {},
    },
    createResearchProjectRes: {
      status: null,
      data: {},
    },
    getAllResearchProjectsRes: {
      status: null,
      data: {},
    },
    getIndividualResearchProjectRes: {
      status: null,
      data: {},
    },
    editResearchProjectRes: {
      status: null,
      data: {},
    },
    getResearchElligibleRespondentsRes: {
      status: null,
      data: {},
    },
    getResearchFinalizeRespondentsRes: {
      status: null,
      data: {},
    },
    getMetaForRespondentRes: {
      status: null,
      data: {},
    },
    getScreenedQuestionForRespondentRes: {
      status: null,
      data: {},
    },
    finalizeRespondentRes: {
      status: null,
      data: {},
    },
    moveFinalizedRespondentToHisoryRes: {
      status: null,
      data: {},
    },
    getFinalizedRespondentContactRes: {
      status: null,
      data: {},
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllMeta.pending.toString(), (state, action) => {
        return {
          ...state,
          getAllMetaRes: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(getAllMeta.fulfilled.toString(), (state, { payload }) => {
        return {
          ...state,
          getAllMetaRes: {
            status: status.SUCCESS,
            data: payload.data,
          },
        };
      })
      .addCase(getAllMeta.rejected.toString(), (state, { error }) => {
        return {
          ...state,
          getAllMetaRes: {
            status: status.FAILURE,
            data: error?.message,
          },
        };
      })

      .addCase(createResearchProject.pending.toString(), (state, action) => {
        return {
          ...state,
          createResearchProjectRes: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(
        createResearchProject.fulfilled.toString(),
        (state, { payload }) => {
          return {
            ...state,
            createResearchProjectRes: {
              status: status.SUCCESS,
              data: payload,
            },
          };
        }
      )
      .addCase(
        createResearchProject.rejected.toString(),
        (state, { error }) => {
          return {
            ...state,
            createResearchProjectRes: {
              status: status.FAILURE,
              data: error?.message,
            },
          };
        }
      )

      .addCase(getAllResearchProjects.pending.toString(), (state, action) => {
        return {
          ...state,
          getAllResearchProjectsRes: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(
        getAllResearchProjects.fulfilled.toString(),
        (state, { payload }) => {
          return {
            ...state,
            getAllResearchProjectsRes: {
              status: status.SUCCESS,
              data: payload.data,
            },
          };
        }
      )
      .addCase(
        getAllResearchProjects.rejected.toString(),
        (state, { error }) => {
          return {
            ...state,
            getAllResearchProjectsRes: {
              status: status.FAILURE,
              data: error?.message,
            },
          };
        }
      )

      .addCase(
        getIndividualResearchProject.pending.toString(),
        (state, action) => {
          return {
            ...state,
            getIndividualResearchProjectRes: {
              status: status.IN_PROGRESS,
            },
          };
        }
      )
      .addCase(
        getIndividualResearchProject.fulfilled.toString(),
        (state, { payload }) => {
          return {
            ...state,
            getIndividualResearchProjectRes: {
              status: status.SUCCESS,
              data: payload.data,
            },
          };
        }
      )
      .addCase(
        getIndividualResearchProject.rejected.toString(),
        (state, { error }) => {
          return {
            ...state,
            getIndividualResearchProjectRes: {
              status: status.FAILURE,
              data: error?.message,
            },
          };
        }
      )

      .addCase(editResearchProject.pending.toString(), (state, action) => {
        return {
          ...state,
          editResearchProjectRes: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(
        editResearchProject.fulfilled.toString(),
        (state, { payload }) => {
          return {
            ...state,
            editResearchProjectRes: {
              status: status.SUCCESS,
              data: payload.data,
            },
          };
        }
      )
      .addCase(editResearchProject.rejected.toString(), (state, { error }) => {
        return {
          ...state,
          editResearchProjectRes: {
            status: status.FAILURE,
            data: error?.message,
          },
        };
      })

      .addCase(
        getResearchElligibleRespondents.pending.toString(),
        (state, action) => {
          return {
            ...state,
            getResearchElligibleRespondentsRes: {
              status: status.IN_PROGRESS,
            },
          };
        }
      )
      .addCase(
        getResearchElligibleRespondents.fulfilled.toString(),
        (state, { payload }) => {
          return {
            ...state,
            getResearchElligibleRespondentsRes: {
              status: status.SUCCESS,
              data: payload,
            },
          };
        }
      )
      .addCase(
        getResearchElligibleRespondents.rejected.toString(),
        (state, { error }) => {
          return {
            ...state,
            getResearchElligibleRespondentsRes: {
              status: status.FAILURE,
              data: error?.message,
            },
          };
        }
      )

      .addCase(
        getResearchFinalizeRespondents.pending.toString(),
        (state, action) => {
          return {
            ...state,
            getResearchFinalizeRespondentsRes: {
              status: status.IN_PROGRESS,
            },
          };
        }
      )
      .addCase(
        getResearchFinalizeRespondents.fulfilled.toString(),
        (state, { payload }) => {
          return {
            ...state,
            getResearchFinalizeRespondentsRes: {
              status: status.SUCCESS,
              data: payload,
            },
          };
        }
      )
      .addCase(
        getResearchFinalizeRespondents.rejected.toString(),
        (state, { error }) => {
          return {
            ...state,
            getResearchFinalizeRespondentsRes: {
              status: status.FAILURE,
              data: error?.message,
            },
          };
        }
      )

      .addCase(getMetaForRespondent.pending.toString(), (state, action) => {
        return {
          ...state,
          getMetaForRespondentRes: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(
        getMetaForRespondent.fulfilled.toString(),
        (state, { payload }) => {
          return {
            ...state,
            getMetaForRespondentRes: {
              status: status.SUCCESS,
              data: payload,
            },
          };
        }
      )
      .addCase(getMetaForRespondent.rejected.toString(), (state, { error }) => {
        return {
          ...state,
          getMetaForRespondentRes: {
            status: status.FAILURE,
            data: error?.message,
          },
        };
      })

      .addCase(
        getScreenedQuestionForRespondent.pending.toString(),
        (state, action) => {
          return {
            ...state,
            getScreenedQuestionForRespondentRes: {
              status: status.IN_PROGRESS,
            },
          };
        }
      )
      .addCase(
        getScreenedQuestionForRespondent.fulfilled.toString(),
        (state, { payload }) => {
          return {
            ...state,
            getScreenedQuestionForRespondentRes: {
              status: status.SUCCESS,
              data: payload,
            },
          };
        }
      )
      .addCase(
        getScreenedQuestionForRespondent.rejected.toString(),
        (state, { error }) => {
          return {
            ...state,
            getScreenedQuestionForRespondentRes: {
              status: status.FAILURE,
              data: error?.message,
            },
          };
        }
      )

      .addCase(finalizeRespondent.pending.toString(), (state, action) => {
        return {
          ...state,
          finalizeRespondentRes: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(
        finalizeRespondent.fulfilled.toString(),
        (state, { payload }) => {
          return {
            ...state,
            finalizeRespondentRes: {
              status: status.SUCCESS,
              data: payload,
            },
          };
        }
      )
      .addCase(finalizeRespondent.rejected.toString(), (state, { error }) => {
        return {
          ...state,
          finalizeRespondentRes: {
            status: status.FAILURE,
            data: error?.message,
          },
        };
      })

      .addCase(
        moveFinalizedRespondentToHisory.pending.toString(),
        (state, action) => {
          return {
            ...state,
            moveFinalizedRespondentToHisoryRes: {
              status: status.IN_PROGRESS,
            },
          };
        }
      )
      .addCase(
        moveFinalizedRespondentToHisory.fulfilled.toString(),
        (state, { payload }) => {
          return {
            ...state,
            moveFinalizedRespondentToHisoryRes: {
              status: status.SUCCESS,
              data: payload,
            },
          };
        }
      )
      .addCase(
        moveFinalizedRespondentToHisory.rejected.toString(),
        (state, { error }) => {
          return {
            ...state,
            moveFinalizedRespondentToHisoryRes: {
              status: status.FAILURE,
              data: error?.message,
            },
          };
        }
      )

      .addCase(
        getFinalizedRespondentContact.pending.toString(),
        (state, action) => {
          return {
            ...state,
            getFinalizedRespondentContactRes: {
              status: status.IN_PROGRESS,
            },
          };
        }
      )
      .addCase(
        getFinalizedRespondentContact.fulfilled.toString(),
        (state, { payload }) => {
          
          return {
            ...state,
            getFinalizedRespondentContactRes: {
              status: status.SUCCESS,
              data: payload,
            },
          };
        }
      )
      .addCase(
        getFinalizedRespondentContact.rejected.toString(),
        (state, { error }) => {
          return {
            ...state,
            getFinalizedRespondentContactRes: {
              status: status.FAILURE,
              data: error?.message,
            },
          };
        }
      );
  },
});

export default ResearchMetaSlice.reducer;
